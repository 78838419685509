.newpay {
    width: 100%;
    font-size: 16px;
}

.newpay_box {
    width: 90%;
    margin: 10px auto;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 0 5px;
}

.PayType_list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #dbdbde;
}

.PayType_list img {
    width: 40px;
}

.PayType_list p {
    margin: 0 5px;
    font-size: 17px;
}

.PayType_list>div {
    display: flex;
    align-items: center;
}

.newPay_model,
.newPay_moneyInput {
    width: 90%;
    height: 60px;
    background-color: #fff;
    margin: 10px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    border-radius: 8px;
}

.newPay_moneyInput>input {
    width: 60%;
    height: 80%;
    border: 1px solid #575759;
    border-radius: 8px;
    padding: 5px;
}

.newPay_model p {
    width: 45%;
    text-align: center;
    font-size: 15px;
}

.newPay_model>p:last-child {
    color: #969799;
}

.adm-picker-header-button {
    color: #0071DC;
}

.submit_Pay {
    width: 80%;
    height: 40px;
    background-color: #0071DC;
    border-radius: 8px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    font-size: 16px;
}

/* ////////////////////// */
/* UserPay */
.UserPay {
    width: 100%;
    font-size: 16px;
}

.UserPay_box {
    width: 90%;
    height: auto;
    background-color: #fff;
    border-radius: 15px;
    margin: 5% auto;
    overflow: hidden;
    box-shadow: rgba(41, 5, 5, 0.15) 0px 0px 10px;
}

.userPay_top {
    width: 100%;
    height: 62px;
    background-color: #0071DC;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
}

.userPay_top>div:first-child {
    display: flex;
    align-items: center;
}

.userPay_top img {
    width: 40px;
    margin: 0 8px;
}

.qrcode {
    width: 100%;
    height: 442px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.qrcode>div:first-child {
    display: flex;
    font-size: 13px;
    color: darkgray;
    line-height: 20px;
}

.code {
    width: 208px;
    height: 208px;
    background: url('/public/assets/index/qrcode.svg') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payCode {
    height: 41px;
    padding: 0 5px;
    background-color: rgba(242, 242, 242, 1);
    color: rgba(255, 153, 0, 1);
    border-radius: 10px;
    font-size: 15px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    overflow-x: auto;
    width: calc(100% - 30px);
}

.copy {
    width: 70%;
    height: 40px;
    background-color: #0071DC;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}